<template>
  <v-navigation-drawer
    v-model="toggle"
    app
    fixed
    floating
    width="100vw"
    color="background"
    class="notifications"
  >
    <v-toolbar 
      flat
      fixed
      color="transparent"
      class="page-header notification-navbar"
    >
      <v-btn
        icon
        :color="hasUnread ? 'primary' : undefined"
        @click="close"
      >
        <v-icon>
          {{ controller.level==0 ? hasUnread ? icons.unread : icons.notification : icons.back }}
        </v-icon>
      </v-btn>
      <v-spacer />
      <v-toolbar-title 
        class="title text-body-1"
      >
        Notificações
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        @click="close"
      >
        <v-icon>
          {{ icons.close }}
        </v-icon>
      </v-btn>
    </v-toolbar>

    <!-- <v-divider class="my-0" /> -->

    <v-window
      v-model="controller.level"
      touchless
      class="px-0"
    >
      <v-window-item>
        <div
          v-if="empty"
          class="empty-placeholder text-center mt-8 pa-6"
        >
          <img 
            class="empty-icon mx-auto" 
            src="/img/empty-notifications.svg"
          >
          <h4 class="title text-h6 mt-8">
            Central de Notificações
          </h4>
          <p class="text body-2 text--secondary my-6">
            Você não tem notificações por enquanto. Nossas comunicações, avisos e lembretes ficarão registradas aqui. <b>Fique atento ao símbolo da Mobees no canto superior esquerdo da página principal.</b>
          </p>
        </div>
        <v-list
          v-else
          ref="notifications"
          max-height="calc(100vh - 57px)"
          class="notifications-list scrollable pt-0 pb-8 transparent"
        >
          <v-list-item
            v-for="item in list"
            :key="'notification-'+item.id"
            :class="{ 'read': item.read!=null }"
            class="item px-0"
            @click="open(item)"
          >
            <v-list-item-content class="content px-5">
              <v-list-item-title 
                :class="[(item.read==null ? 'primary' : 'text--lighten-2 grey')+'--text']"
                class="title mt-2 text-subtitle-1"
              >
                {{ item.title }}
              </v-list-item-title>
              <v-list-item-subtitle 
                :class="[(item.read==null ? 'white' : 'grey')+'--text']"
                class="text text-body-2 my-1 text-untruncate"
                v-html="item.text"
              />
              <v-list-item-subtitle class="timestamp text-caption grey--text my-1">
                {{ item.sent | formatDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-window-item>
      <v-window-item>
        <v-card
          class="pa-6"
        >
          <h6 class="title text-h6 mt-2 mb-4">
            {{ selected.title }}
          </h6>
          <p 
            class="text body-2 my-4"
            v-html="selected.text" 
          />
        </v-card>
      </v-window-item>
    </v-window>

  </v-navigation-drawer>
</template>

<style>

  .notifications {
    padding: env(safe-area-inset-top) 0 0 env(safe-area-inset-left) !important;
  }

  /* .notifications-list .item {
    border-bottom: 1px solid var(--v-grey-darken3);
  }*/
  .notifications-list .content {
    border-left: 4px solid var(--v-accent-base);
  } 
  .notifications-list .read .content {
    border-left: 4px solid transparent;
  }
  .notifications-list .item .text {
    line-height: 1.4 !important;
    height: auto !important;
  }
  .v-navigation-drawer__border {
    display: none;
  }
  
</style>

<script>
  import {
    sync,
    get
  } from 'vuex-pathify'
  import services from '@/services.js'
  // Icons
  import {
    mdiBell, mdiBellBadge, mdiChevronLeft, mdiClose
  } from '@mdi/js'

  const moment = require('moment');

  export default {
    name: 'Notifications',

    props: {
      active: {
        type: Boolean,
        default: true
      },
      installed: {
        type: Boolean,
        default: false
      },
      modal: {
        type: String,
        default: null
      },
      dev: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      icons: {
        notification: mdiBell,
        unread: mdiBellBadge,
        back: mdiChevronLeft,
        close: mdiClose
      },
      controller: {
        selected: null,
        level: 0
      }
    }),

    computed: {
      view: sync('app/views@notifications'),
      help: sync('app/views@help'),
      tickets: get('app/views@help.tickets'),
      ticket: sync('app/views@help.ticket'),
      user: get('user'),

      toggle: {
        get () {
          const toggle = this.modal=='notifications';
          return toggle;
        },
        set (b) {
          // if (!b) {
          //   this.toggleView();
          // }
        },
      },
      
      hasUnread () {
        return _.some(this.view.items, ['read', null]);
      },
      empty () {
        return _.size(this.view.items)==0;
      },
      list () {
        return _.orderBy(_.map(this.view.items, item => {
          item.text = this.formatText(item.text);
          return item;
        }), ['sent'], ['desc']);
      },
      selected () {
        return this.controller.selected==null||!_.has(this.view.items, this.controller.selected) ? { title: '', text: '' } : this.view.items[this.controller.selected];
      }
    },

    watch: {
      controller (controller) {
        if (controller.level==0) this.controller.selected = null;
      },
      toggle: {
        immediate: true,
        deep: true,
        handler (toggle, before) {
          if (!toggle&&before) {
            const read = _.filter(this.view.items, ['read', null]);
            if (read.length>0) this.setNotifications(read);
          }
        }
      },
    },

    methods: {
      ...services,

      open (item) {
        console.log(item);
        setTimeout(($) => {
          if (item.reference!=null) {
            let [type, id] = _.split(item.reference, '#');
            if (type=='ticket'&&_.has(this.tickets, id)&&!this.tickets[id].closed) {
              $.toggleTicket(id);
            }else if (type=='calendar') {
              const ticket = _.find(this.tickets, ['data.slot', id]);
              if (!_.isNil(ticket)&&!ticket.closed) {
                $.toggleTicket(ticket.id);
              }
            }else if (type=='flashmob') {
              $.toggleEvent(id);
            }
          }
          // $.controller.selected = notification.id;
          // $.controller.level = 1;
        }, 250, this);
      },

      setNotifications (read) {
        const cpf = this.rawCPF(this.user.cpf);
        const token = this.user.auth.token;

        read = _.map(read, n => {
          return { id: n.id, read: moment.utc().format('YYYY-MM-DD HH:mm:ss') }
        })

        this.$api
          .post('/setdrivernotifications/'+cpf+'/'+token+'/?format=json', read)
          .then(response => {
            console.log('setNotifications => ', response);
            if (response.data.retorno!=401) {
              const notifications = _.keyBy(read, 'id');
              this.view.items = _.merge({}, this.view.items, notifications);
            }
          })
      },

      close () {
        if (this.controller.level==0) {
          this.toggleView();
        }else{
          this.controller.level = 0;
        }
      },

      setEvents () {
        if ('notifications' in this.$refs) {
          this.$refs['notifications'].$el.addEventListener('click', (e) => {
            console.log(e);
            if ('popup' in e.target.dataset) {
              e.preventDefault();
              const url = ('file' in e.target.dataset ? this.getAppRoot()+'/file?url=' : '') + e.target.href;
              this.popup(url);
            }
          });
        }else{
          setTimeout(($) => {
            $.setEvents();
          }, 250, this);
        }
      }
    },

    filters: {
      formatDate (date) {
        return moment(date).fromNow();
      }
    },

    mounted () {
      this.$nextTick(() => {
        this.setEvents();
      })
    }
  }
</script>
